//Colours

$c-yellow: #FFCC00;

//Breakpoints
$mobile-small: 321px;
$mobile: 480px;
$tablet: 768px;
$tablet-large: 813px;
$desktop-small: 1024px;
$desktop: 1280px;


@mixin for-size($size) {
  @if $size == $mobile-small {
    @media (min-width: $mobile-small) { @content; }
  } @else if $size == $mobile {
    @media (min-width: $mobile) { @content; }
  } @else if $size == $tablet {
    @media (min-width: $tablet) { @content; }
  } @else if $size == $tablet-large {
    @media (min-width: $tablet-large) { @content; }
  } @else if $size == $desktop-small {
    @media (min-width: $desktop-small) { @content; }
  } @else if $size == $desktop {
    @media (min-width: $desktop) { @content; }
  }
}
