body {
  position: relative;
  font-family: 'New Transport Bold', arial, sans-serif;
  font-size: 16px;
  line-height: 20px;

  background-color: #000000;
  color: #ffffff;
  margin: 0;
  padding: 0;

  @include for-size($mobile) {
    font-size: 18px;
    line-height: 22px;
  }

  &.bg-fixed {
    -webkit-overflow-scrolling: touch;
    overflow: hidden;
  }
}

p {
  margin-top: 0;

  &:last-of-type {
    margin-bottom: 0;
  }

}


img {
  height: auto;
  max-width: 100%;
}

button {
  &:focus {
    outline: none;
  }
}

.button {
  position: relative;
  height: auto;
  width: 100%;
  //max-width: 198px;
  max-width: 240px;
  background-color: transparent;
  border: 2.04px solid $c-yellow;
  border-radius: 7.13px;

  font-family: 'New Transport Bold', arial, sans-serif;
  font-size: 18.35px;
  letter-spacing: 1.94px;
  line-height: 22px;
  color: #FFFFFF;
  padding: 20px 20px 20px 65px;
  text-transform: uppercase;
  text-decoration: none;
  text-align: left;

  &:before {
    content: '';
    position: absolute;
    left: 26px;
    top: 50%;
    transform: translateY(-50%);
    background-image: url(./images/play.svg);
    /* background-size: cover; */
    background-position: center;
    background-repeat: no-repeat;
    height: 17.02px;
    width: 18.02px;
  }

  &--small {
    font-size: 16.13px;
    letter-spacing: 1.7px;

    max-width: 206px;
    padding: 18px 8px;
    border: 1.79px solid $c-yellow;
  }

  &--disable {
    &:after {
      content: '';
      background: #000000ab;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 7.13px;
    }
  }
}


.close {
  position: absolute;
  right: 0;
  top: -40px;

  height: 50px;
  width: 50px;

  background-color: transparent;
  border: none;

  @include for-size($tablet) {
    right: -45px;
    top: -45px;
  }

  &:hover {
    opacity: 1;
  }

  &:focus {
    outline: none;
  }

  &__wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    width: auto;
    height: 32px;

    opacity: 0.7;

    //transition: opacity 0.3s linear;

    @include for-size($tablet) {
      width: 45px;
      height: 45px;
    }
  }

  &__line {
    position: absolute;
    top: 0;
    content: ' ';
    height: 33px;
    width: 2px;
    background-color: #fff;

    @include for-size($tablet) {
      height: 45px;
    }

    &--1 {
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    &--2 {
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }
  }
}

#backToTop {
  display: none;
  position: fixed;
  right: 20px;
  bottom: 0;

  height: 36px;
  width: 36px;

  background-image: url('../src/images/back-to-top.svg');
  background-size: contain;
  background-repeat: no-repeat;


  &.active {
    display: block;
  }
}

.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.load-more {
  background-color: $c-yellow;
  color: #222;
  padding: 1em 1.5em;
  text-decoration: none;
  border-radius: 5px;
  border-color: transparent;
  font-weight: bold;

  margin: 20px auto 0;

  @include for-size($mobile) {
    margin: 0 auto;
  }
}
