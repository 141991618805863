.gallery {
  position: relative;
  width: calc(100% - 44px);
  max-width: 1290px;
  padding-left: 22px;
  padding-right: 22px;
  margin: 82px auto 0;

  @include for-size($desktop) {
    margin: 120px auto 0;
  }

  &:after {
    content: "";
    clear: both;
    display: table;
  }

  h2 {
    @include for-size($desktop) {
      margin-bottom: 80px;
    }
  }

  &__subtitle {
    width: 100%;
    font-family: $font-heading;
    font-size: 18px;
    letter-spacing: -0.25px;
    line-height: 26px;
    margin-bottom: 10px;

    @include for-size($desktop) {
      font-size: 22px;
      letter-spacing: 3.13px;
      line-height: 32px;
      margin-bottom: 27px;
    }
  }

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    //max-width: calc(1224px - 24px);
    width: 100%;

    border-bottom: 1px solid #fff;
    padding-bottom: 14px;
    margin-bottom: 26px;

    @include for-size($desktop) {
      //margin-left: -12px;
      //margin-right: -12px;
      //float: right;

      padding-bottom: 43px;
      margin-bottom: 40px;
    }

    &:after {
      content: "";
      clear: both;
      display: table;
    }

    //&--contestant {
    //  border-bottom: 1px solid #fff;
    //  padding-bottom: 14px;
    //  margin-bottom: 26px;
    //
    //  @include for-size($desktop) {
    //    padding-bottom: 43px;
    //    margin-bottom: 40px;
    //  }
    //}
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    //max-width: 1224px;
    width: 100%;

    @include for-size($mobile) {
      margin-left: -12px;
      margin-right: -12px;
    }

    @include for-size($tablet) {
      width: auto;
    }

    .modal {
      display: block;
      height: auto;
      width: 100%;
      //margin: 0 12px 12px;
      //margin: 0;
      background-color: #222;
      padding: 12px;
      margin-bottom: 12px;

      &:last-child {
        @media(max-width: $mobile) {
          margin-bottom: 0;
        }
      }

      @include for-size($mobile) {
        width: calc(50% - 44px);
        margin: 0 12px 30px;
      }

      @include for-size($tablet) {
        //width: calc(33% - 24px);
        //margin: 0 12px 30px;
        width: calc(33% - 46px);
      }

      @include for-size($desktop) {
        width: calc(33% - 44px);
      }
    }
  }

  &__video {
    display: flex;
    width: 100%;

    @include for-size($tablet) {
      flex-wrap: wrap;
    }

    &__image {
      position: relative;
      width: 100%;
      margin-right: 17px;
      line-height: 0;

      @include for-size($tablet) {
        margin-right: 0;
      }

      &:hover {
        cursor: pointer;
      }

      .modal {
        position: relative;

        .modal__wrapper {
          display: inline-block;
          line-height: 0;
        }
      }

      .gallery__video__button {
        display: block;

        @include for-size($tablet) {
          display: none;
        }
      }
    }

    &__title {
      position: relative;
      display: flex;
      width: 100%;

      //font-family: $font-regular;
      //color: $c-yellow;
      color: #fff;
      font-size: 16px;
      line-height: 19px;

      @include for-size($tablet) {
        margin-top: 14px;
        align-items: center;

        padding: 0.25em 0;
      }

      @include for-size($desktop) {
        //font-size: 22px;
        //line-height: 26px;

        font-size: 18px;
        line-height: 22px
      }

      .gallery__video__button {
        display: none;

        @include for-size($tablet) {
          display: block;
          position: relative;
          bottom: unset;
          right: unset;
          margin-right: 13px;
        }
      }
    }

    &__button {
      position: absolute;
      bottom: 0;
      right: 0;

      display: block;
      height: 28px;
      width: 29px;
      border: none;
      transition: all .2s linear;

      background-image: url(./images/video.svg);
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      background-color: rgba(0,0,0,0.52);

      &:hover {
          background-image: url(./images/video-hover.svg);
      }

      &:focus {
        outline: none;
      }
    }
  }
}
