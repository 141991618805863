.header {
  position: absolute;
  width: calc(100% - 44px);
  padding: 25px 22px;
  z-index: 11;

  display: flex;
  justify-content: space-between;

  @include for-size($mobile) {
    width: calc(100% - 70px);
    padding: 35px;
  }

  &__left,
  &__right {
    display: flex;
    flex-direction: column;
  }

  &__brand {
    display: none;

    @include for-size($mobile) {
      display: block;
      max-width: 210px;
    }
  }

  &__hashtag {
    font-size: 24px;
    letter-spacing: 1.37px;
    line-height: 28px;
    text-align: center;
    margin-top: 10px;
  }


  &__logo {
    display: inline-block;
    text-align: center;

    img {
      max-width: 47px;

      @include for-size($mobile) {
        max-width: 70px;
      }
    }

    &--leaderboard {
      margin-top: 28px;
      padding: 0;

      background: none;
      border: none;

      img {
        max-width: 55px;

        @include for-size($mobile) {
          max-width: 76px;
        }
      }
    }
  }
}
