.leaderboard {
  position: relative;
  width: calc(100% - 44px);
  max-width: 1290px;
  padding-left: 22px;
  padding-right: 22px;
  margin: 82px auto 0;

  @include for-size($desktop) {
    margin: 120px auto 0;
  }

  &:after {
    content: "";
    clear: both;
    display: table;
  }

  h2 {
    @include for-size($tablet) {
      margin-bottom: 15px;
    }
  }

  &__description {
    display: none;
    max-width: 500px;
    margin: 0 auto;
    font-size: 18px;
    line-height: 22px;
    text-align: center;

    @include for-size($tablet) {
      display: block;
    }
  }

  &__content {
    display: block;
    //max-width: 1200px;
    width: 100%;
    //float: right;

    @include for-size($tablet) {
      margin-top: 45px;
     column-count: 2;
    }

    @include for-size($desktop) {
      margin-top: 80px;
    }
  }

  .participant {
    display: flex;
    //justify-content: center;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
    max-width: 490px;
    width: 100%;

    @include for-size($tablet) {
      margin-bottom: 25px;
    }

    @include for-size($desktop) {
      padding-top: 10px;
      margin-bottom: 50px;
    }

    &__right {
      margin-left: auto;
      margin-right: 25px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &:first-child {
      .modal {
        &__wrapper {
          border: 4px solid $c-yellow;

          @include for-size($desktop) {
            border: 7px solid $c-yellow;
          }
        }
      }
    }

    &:hover {
      &:first-child {
        .modal {
          .modal__wrapper {
            &:before {
              border-color: $c-yellow;
            }
          }
        }
      }
    }

    &:after {
      content: "";
      clear: both;
      display: table;
    }

    &__rank {
      font-family: $font-heading;
      font-size: 22px;
      letter-spacing: 2.13px;
      line-height: 25px;
      color: $c-yellow;

      @include for-size($tablet) {
        font-size: 32px;
        line-height: 35px;
      }

      @include for-size($desktop) {
        font-size: 42.8px;
        letter-spacing: -0.6px;
        line-height: 62px;
        width: 50px;
        text-align: center;
      }
    }

    &__name {
      font-family: $font-heading;
      font-size: 18px;
      letter-spacing: -0.25px;
      line-height: 26px;

      @include for-size($tablet) {
        font-size: 32px;
        line-height: 35px;
      }

      @include for-size($desktop) {
        font-size: 46.6px;
        letter-spacing: -0.66px;
        line-height: 67px;
      }
    }

    &__points {
      font-family: $font-regular;
      font-size: 15.21px;
      font-weight: bold;
      letter-spacing: -0.21px;
      line-height: 18px;
      color: $c-yellow;

      @include for-size($tablet) {
        font-size: 18px;
        line-height: 20px;
      }

      @include for-size($desktop) {
        font-size: 22.8px;
        letter-spacing: -0.32px;
        line-height: 27px;
      }
    }

    &__image {
      transition: transform .5s ease;
    }

    .modal {
      display: inline-block;
      line-height: 0;

      max-width: 80px;
      padding-left: 40px;
      padding-right: 20px;
      cursor: pointer;

      @include for-size($tablet) {
        max-width: 120px;
      }

      @include for-size($desktop) {
        max-width: 150px;
        padding-left: 50px;
        padding-right: 50px;
      }

      //&:hover {
      //  .modal__wrapper {
      //    &:before {
      //      height: calc(100% - 20px);
      //      width: calc(100% - 20px);
      //      border: 10px solid #303030;
      //    }
      //  }
      //}

      &__wrapper {
        position: relative;
        display: inherit;
        overflow: hidden;
        border-radius: 50%;
        border: 4px solid #303030;

        -webkit-transition: all .2s linear;
        -moz-transition: all .2s linear;
        -ms-transition: all .2s linear;
        -o-transition: all .2s linear;
        transition: all .2s linear;

        @include for-size($desktop) {
          border: 7px solid #303030;
        }

        &:hover {

          @include for-size($desktop) {
            transform: scale(1.04);
            box-shadow: 0 0 8px #fff;
          }
        }

        //http://ant24live.uatserver.co.uk/content/wp-content/uploads/2019/07/Tamar-profile.jpg
        //&:after {
        //  content: '';
        //  position: absolute;
        //  top: 0;
        //  left: 0;
        //  height: calc(100% - 8px);
        //  width: calc(100% - 8px);
        //  border: 4px solid #303030;
        //  border-radius: 50%;
        //
        //  @include for-size($desktop) {
        //    height: calc(100% - 14px);
        //    width: calc(100% - 14px);
        //    border: 7px solid #303030;
        //  }
        //}
        //
        //&:before {
        //  content: '';
        //  position: absolute;
        //  top: 0;
        //  left: 0;
        //  height: calc(100% - 8px);
        //  width: calc(100% - 8px);
        //  border: 4px solid #303030;
        //  border-radius: 50%;
        //
        //  -webkit-transition: all .2s linear;
        //  -moz-transition: all .2s linear;
        //  -ms-transition: all .2s linear;
        //  -o-transition: all .2s linear;
        //  transition: all .2s linear;
        //
        //  @include for-size($desktop) {
        //    height: calc(100% - 14px);
        //    width: calc(100% - 14px);
        //    border: 7px solid #303030;
        //  }
        //}
      }
    }
  }
}
