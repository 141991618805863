.footer {
  display: block;

  margin-top: 35px;
  padding: 50px 0 30px;

  background-color: #ffcc01;

  text-align: center;

  @include for-size($tablet) {
    margin-top: 92px;
  }


  &__inner {
    max-width: 375px;
    width: 100%;
    margin: 0 auto;
  }

  h2 {
    color: #000;
    margin-bottom: 0;

    font-size: 2em;
    line-height: 1;
    letter-spacing: 0;

    &.footer__banner-text {
      font-size: 3em;
    }
  }

  .footer__banner_text {
    max-width: 300px;
    font-size: 42px;
    margin: 0 auto;
  }

  p {
    color: #000;
    font-size: 20px;
    margin-top: 30px;
  }

  &__button {
    position: relative;
    background-color: #000;
    text-decoration: none;
    color: #fff;
    padding: 20px 30px;
    display: inline-block;
    border-radius: 7.13px;
    text-transform: uppercase;
    font-weight: 300;
    letter-spacing: 2px;
    margin-top: 30px;
  }

  &__links {
    margin: 40px 0 0;
    padding: 0;

    list-style: none;

    li {
      display: inline-block;

      padding: 0 10px;
      border-right: 1px solid #333;

      font-size: 0.8em;
      line-height: 1;

      &:last-of-type {
        padding-right: 0;

        border: none;
      }
    }

    li a {
      color: #333;
      text-decoration: none;
    }
  }
}
