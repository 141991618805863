.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width:100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  z-index: 11;
  transition: all .5s ease;

  &__container {
    position:fixed;
    background: white;
    width: 80%;
    height: auto;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
  }

  &--visible {
    opacity: 1;
    visibility: visible;
  }

  &--hidden {
    opacity: 0;
    visibility: hidden;
  }

  &__wrapper {
    position: relative;
    padding-top: 46%;
  }

  &__player {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
