.challenge {
  position: relative;
  width: calc(100% - 44px);
  max-width: 1290px;
  padding-left: 22px;
  padding-right: 22px;
  margin: 64px auto 0;

  @include for-size($desktop) {
    margin: 114px auto 0;
  }

  &:after {
    content: "";
    clear: both;
    display: table;
  }

  &__wrapper{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    //max-width: 1200px;
    float: right;
  }

  &__map {
    width: 100%;

    @include for-size($tablet) {
      width: 55%;
    }

    @include for-size($desktop) {
      width: 100%;
      max-width: 676px;
    }
  }

  &__content {
    width: 100%;

    @include for-size($tablet) {
      width: 40%;
    }

    @include for-size($desktop) {
      width: 100%;
      max-width: 415px;
    }

    &__subheading {
      font-family: $font-heading;
      font-size: 18px;
      letter-spacing: -0.25px;
      line-height: 26px;

      @include for-size($tablet) {
        font-size: 22px;
        letter-spacing: 3.13px;
        line-height: 32px;
      }
    }

    &__number {
      font-family: $font-heading;
      font-size: 35px;
      letter-spacing: 3.66px;
      line-height: 50px;
      color: $c-yellow;

      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;

      @include for-size($tablet) {
        font-size: 58px;
        letter-spacing: 6.12px;
        line-height: 84px;
      }
    }

    &__description {
      margin-bottom: 18px;
    }

    &__voting {
      margin-bottom: 22px;
    }
  }
}
