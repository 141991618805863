.about {
  position: relative;
  width: calc(100% - 44px);
  max-width: 1290px;
  padding-left: 22px;
  padding-right: 22px;
  margin: 46px auto 0;

  &:after {
    content: "";
    clear: both;
    display: table;
  }

  &__subheading {
    font-family: $font-heading;
    font-size: 34px;
    line-height: 39px;
    color: #fff;
    text-transform: uppercase;

    max-width: 300px;
    margin-bottom: 20px;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @include for-size($mobile) {
      font-size: 36px;
      line-height: 40px;

      max-width: 355px;
    }

    @include for-size($desktop-small) {
      font-size: 44px;
      line-height: 44px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include for-size($tablet) {
      flex-direction: row;
    }

    .modal {
      @media (max-width: $tablet) {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
      }
    }

    &__video {
      position: relative;
      width: 100%;
      max-width: 100%;
      padding-top: 64%;
      margin-bottom: 20px;

      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;

      @include for-size($tablet) {
        width: 55%;
        padding-top: 0;
      }

      @include for-size($desktop) {
        width: 100%;
        max-width: 765px;
      }

      &__button {
        width: auto;
        max-width: none;
        padding-left: 70px;

        @include for-size($tablet) {
          font-size: 20.16px;
          letter-spacing: 2.13px;
          line-height: 24px;
        }
      }
    }

    &__description {
      position: relative;
      width: 100%;

      @include for-size($tablet) {
        width: 40%;
      }

      @include for-size($desktop) {
        width: 100%;
        max-width: 415px;
      }
    }
  }

  .social {
    @include for-size($tablet) {
      float: right;
      width: 40%;
      margin-top: 50px;
    }

    @include for-size($desktop) {
      width: 100%;
      max-width: 415px;
    }

  }
}
