.social {
  display: block;
  text-align: center;
  margin-top: 15px;
  //font-family: 'New Transport Bold';

  @include for-size($mobile) {
    margin-top: 22px;
  }
  //
  //&:not(.social--voting) {
  //  width: 100%;
  //}

  &__icons {
    list-style-type: none;
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 90px;
    margin: 15px auto;
    padding: 12px 22px;
    background: #fff;

    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;

    @include for-size($mobile) {
      max-width: 130px;
      margin: 30px auto;
      padding: 16px 25px;
    }

    li {
      display: inline-block;
      float: left;
      line-height: 0;

      a {
        display: inline-block;
        height: 20px;
        width: 21px;

        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;

        @include for-size($mobile) {
          height: 25px;
          width: 26px;
        }

        &:hover {
          transition: all .2s linear;
        }

        &.twitter {
          background-image: url(./images/twitter.svg);

          &:hover {
            background-image: url(./images/twitter-hover.svg);
          }
        }

        &.instagram {
          background-image: url(./images/instagram.svg);

          &:hover {
            background-image: url(./images/instagram-hover.svg);
          }
        }

        &.facebook {
          background-image: url(./images/facebook.svg);

          &:hover {
            background-image: url(./images/facebook-hover.svg);
          }
        }
      }
    }

    &--small {
      margin: 0;
      padding: 10px 20px;

      @include for-size($mobile) {
        max-width: 102px;
        padding: 16px 25px;
      }

      li {
        a {
          height: 18px;
          width: 19px;

          @include for-size($mobile) {
            height: 20px;
            width: 21px;
          }
        }
      }
    }
  }

  &__hashtag {
    @media (max-width: $mobile) {
      font-size: 20px;
      letter-spacing: 1.14px;
      line-height: 23px;
    }
  }
}
