.hero {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;

  padding-top: 90px;
  padding-bottom: 40px;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  @include for-size($mobile-small) {
    padding-top: 140px;
  }

  @include for-size($mobile) {
    padding-top: 180px;
  }

  @include for-size($tablet-large) {
    height: 100vh;
    min-height: 750px;
    background-image: none !important;
    padding-top: 0;
    padding-bottom: 0;
  }

  &--has-livestream {
    height: auto;
    min-height: 0;
    width: calc(100vh + 38%);
    max-width: 100%;

    padding-bottom: 56%;
    padding-top: 0;
    margin: 0 auto 200px;

    background-image: none !important;
    transform: translateY(160px);


    @include for-size($mobile) {
      margin-bottom: 250px;

      transform: translateY(220px);
    }

    @include for-size($tablet-large) {
      margin-bottom: 0;
      transform: none;
    }


    iframe.live-feed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #000;
    opacity: 0.6;
    @include for-size($tablet-large) {
     content: none;
    }
  }

  &.open {
    .hero__content--top {
      transform: translateY(-100%);
    }

    .hero__content--bottom {
      transform: translateY(100%);
    }

    .hero__overlay__image {
      opacity: 0.99;
      transform: scale(1);
      transition-delay: calc( 0.1s * var(--cell-total) );
    }

    .cell {
      transform: translateY(-100%);
    }

    .cell:nth-child(even) {
      transform: translateY(100%);
    }
  }

  .live-feed {
    height: 100vh;
  }

  &__content {
    position: relative;
    width: 100%;
    z-index: 1;

    transition: transform 1s cubic-bezier(0.63, 0.01, 0.07, 0.99), opacity 1s linear;
    //transition-delay: calc( 0.1s * (var(--cell-total) - var(--cell-index)));
    transition-delay: 0.1s;

    @include for-size($mobile) {
      font-size: 18px;
      line-height: 22px;
    }

    @include for-size($tablet) {
      font-size: 24px;
      letter-spacing: -0.06px;
      line-height: 26px;
    }

    @include for-size($tablet-large) {
      height: 50%;
      position: absolute;
    }

    &:before {
      @include for-size($tablet-large) {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: #000;
        opacity: 0.6;
      }
    }

    &--top {
      transition-delay: 0.2s;
      top: 0;

      @media (max-width: $mobile) {
        height: 53%;
      }

      .hero__content__wrapper {
        justify-content: flex-end;
      }
    }

    &--bottom {
      transition-delay: 0.1s;
      bottom: 0;

      @media (max-width: $mobile) {
        height: 47%;
      }

      .hero__content__wrapper {
        @include for-size($tablet) {
          margin-top: 40px;
        }
      }

    }

    &__wrapper {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100%;
      width: calc(100% - 44px);
      padding-left: 22px;
      padding-right: 22px;

      max-width: 550px;
      margin: 0 auto;
      text-align: center;
      color: #fff;
      z-index: 1;

      @include for-size($mobile) {
        width: calc(100% - 70px);
        padding-left: 35px;
        padding-right: 35px;
      }

      @include for-size($desktop) {
        max-width: 850px;
      }

    }

    &__description {
      display: none;
      width: 100%;
      font-family: 'New Transport Regular', arial, sans-serif;

      @include for-size($tablet) {
        display: block;
      }
    }
  }

  &__brand {
    display: block;
    max-width: 240px;
    margin-bottom: 20px;

    @include for-size($mobile) {
      display: none;
      max-width: 654px;
      width: 100%;
      margin-bottom: 0;
    }

    @include for-size($desktop) {
      width: auto;
    }
  }

  &__countdown {
    display: flex;
    width: 100%;
    justify-content: space-between;

    @include for-size($mobile-small) {
      max-width: calc(100% - 80px);
    }

    @include for-size($tablet) {
      max-width: none;
    }

    &__time {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      font-family: "Bebas Neue Book";
      font-size: 83px;
      letter-spacing: 1.44px;
      //line-height: 100px;
      line-height: 45px;

      @include for-size($mobile) {
        font-size: 130px;
        letter-spacing: 4.5px;
        line-height: 70px;
      }

      @include for-size($tablet) {
        font-size: 190px;
        line-height: 100px;
        line-height: 0.85em
      }

      @include for-size($desktop) {
        font-size: 259px;
        letter-spacing: 4.5px;
        //line-height: 225px;
        //line-height: 135px;
        //line-height: 0.85em
      }

      body.mac-styles & {
        // mac style override
        @include for-size($tablet) {
          line-height: 135px;
        }
      }

    }

    &__label {
      font-family: 'Bebas Neue';
      font-size: 23px;
      letter-spacing: 0.4px;
      line-height: 28px;
      margin-bottom: 14px;

      @include for-size($mobile) {
        font-size: 30px;
        letter-spacing: 0.8px;
        line-height: 55px;
        margin-bottom: 20px;
      }

      @include for-size($tablet) {
        font-size: 46px;
        //margin-bottom: 55px;
        line-height: 2em;
        margin-bottom: 0;
      }

      //@include for-size($desktop) {
      //  line-height: 2em;
      //  margin-bottom: 0;
      //}

      body.mac-styles & {
        // mac style override
        @include for-size($tablet) {
          line-height: 70px;
          margin-bottom: 20px;
        }

        @media (min-width: 1025px) {
          margin-bottom: 70px;
        }
      }
    }
  }

  &__button {
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    margin: 35px auto 25px;

    @include for-size($tablet-large) {
      position: absolute;
      bottom: 20px;
      right: 35px;
      left: auto;
      transform: none;
      margin: 0;
    }

    &--disable {
      .hero__button__loader {
        display: block;
      }
    }

    &__loader {
      display: none;
      max-width: 40px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 11;
    }

  }


  &__overlay {
    position: relative;
    height: 100%;
    width: 100%;
    max-width: 100%;

    background-position: center;
    background-repeat: no-repeat;

    &__image {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;

      //padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */
      //padding-top: 46%;


      transform: scale(0.8);
      transition-delay: 0s;
      visibility: visible;
      opacity: 0;

      .react-player {
        position: absolute;
        top: 0;
        left: 0;
      }

      .close {
        right: 42px;
        top: 20%;
      }
    }
  }

  .splitting {
    .cell,
    .cell__inner,
    .hero__overlay__image {
      transition: transform 1s cubic-bezier(.63,.01,.07,.99), opacity 1s linear;
      /* No animation on leave */
      //transition-duration: 0s;
      transition-delay: calc( 0.1s * var(--cell-index) );
      /* Reverse the animation */
      transition-delay: calc( 0.1s * (var(--cell-total) - var(--cell-index)) );
    }

  }


  >.social {
    position: absolute;
    right:  45px;
    top:  50%;
    transform:  translateY(-50%);
    z-index:  1;

    .social__icons {
      &--small {
        flex-direction: column;
        justify-content:  center;
        align-items:  center;

        //max-width: 47px;
        margin : 0;
        padding: 16px 5px;

        li {
          margin-bottom: 20px;

          &:last-child {
            margin-bottom: 0;
          }

          a {
            height: 20px;
            width: 21px;

            @include for-size($mobile) {
              height: 25px;
              width: 26px;
            }
          }
        }
      }
    }
  }

  .social {
    &:not(.social--voting) {
      width: 100%;
    }
  }

}

