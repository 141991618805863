@font-face {
  font-family: 'Bebas Neue';
  src: url('./fonts/BebasNeue-Regular.eot');
  src: url('./fonts/BebasNeue-Regular.eot?#iefix') format('embedded-opentype'),
  url('./fonts/BebasNeue-Regular.woff2') format('woff2'),
  url('./fonts/BebasNeue-Regular.woff') format('woff'),
  url('./fonts/BebasNeue-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Bebas Neue Light';
  src: url('./fonts/BebasNeue-Light.eot');
  src: url('./fonts/BebasNeue-Light.eot?#iefix') format('embedded-opentype'),
  url('./fonts/BebasNeue-Light.woff2') format('woff2'),
  url('./fonts/BebasNeue-Light.woff') format('woff'),
  url('./fonts/BebasNeue-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Bebas Neue Book';
  src: url('./fonts/BebasNeue-Book.eot');
  src: url('./fonts/BebasNeue-Book.eot?#iefix') format('embedded-opentype'),
  url('./fonts/BebasNeue-Book.woff2') format('woff2'),
  url('./fonts/BebasNeue-Book.woff') format('woff'),
  url('./fonts/BebasNeue-Book.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'New Transport Bold';
  src: url('./fonts/NewTransportAAWEB-Bold.eot');
  src: url('./fonts/NewTransportAAWEB-Bold.eot?#iefix') format('embedded-opentype'),
  url('./fonts/NewTransportAAWEB-Bold.woff2') format('woff2'),
  url('./fonts/NewTransportAAWEB-Bold.woff') format('woff'),
  url('./fonts/NewTransportAAWEB-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'New Transport Regular';
  src: url('./fonts/NewTransportAAWEB-Regular.eot');
  src: url('./fonts/NewTransportAAWEB-Regular.eot?#iefix') format('embedded-opentype'),
  url('./fonts/NewTransportAAWEB-Regular.woff2') format('woff2'),
  url('./fonts/NewTransportAAWEB-Regular.woff') format('woff'),
  url('./fonts/NewTransportAAWEB-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Intro Rust G Base 2 Line';
  src: url('./fonts/IntroRustG-Base2Line.eot');
  src: url('./fonts/IntroRustG-Base2Line.eot?#iefix') format('embedded-opentype'),
  url('./fonts/IntroRustG-Base2Line.woff2') format('woff2'),
  url('./fonts/IntroRustG-Base2Line.woff') format('woff'),
  url('./fonts/IntroRustG-Base2Line.ttf') format('truetype'),
  url('./fonts/IntroRustG-Base2Line.svg#IntroRustG-Base2Line') format('svg');
  font-weight: normal;
  font-style: normal;
}

//
//@font-face {
//  font-family: 'intro_head_rbase';
//  src: url('./fonts/introheadr-base-webfont.woff2') format('woff2'),
//  url('./fonts/introheadr-base-webfont.woff') format('woff'),
//  url('./fonts/introheadr-base-webfont.ttf') format('truetype');
//  font-weight: normal;
//  font-style: normal;
//
//}

//@font-face {
//  font-family: 'intro_script_rh2_base';
//  src: url('./fonts/introscriptr-h2base-webfont.woff2') format('woff2'),
//  url('./fonts/introscriptr-h2base-webfont.woff') format('woff'),
//  url('./fonts/introscriptr-h2base-webfont.ttf') format('truetype');
//  font-weight: normal;
//  font-style: normal;
//
//}


$font-heading: 'Intro Rust G Base 2 Line', arial, sans-serif;
$font-regular: 'New Transport Regular', arial, sans-serif;


h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  font-weight: normal;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h2 {
  font-family: $font-heading;
  font-size: 22px;
  letter-spacing: 2.13px;
  line-height: 25px;
  color: $c-yellow;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 30px;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @include for-size($tablet) {
    font-size: 32px;
    letter-spacing: 4.55px;
    line-height: 40px;
    margin-bottom: 60px;
  }

  @include for-size($desktop) {
    //font-size: 62px;
    line-height: 46px;
    margin-bottom: 124px;
  }
}
